@import "../../library";

.offsetContainer {
    display: flex;
    text-align: left;

    .offsetDescription {
        color: #1A1C29;
        width: 40%;
        padding-right: 50px;

        .offsetTitle {
            font-weight: $medium-font-weight;
            font-size: 48px;
            color: $scone-green;
        }

        .offsetInfo {
            padding: 20px 0;
        }

        .offsetList {
            width: 80%;
            font-family: $secondary-font;
            color: $scone-cta;
            font-size: $large-text;

            .selectedOffset {
                font-weight: bold;
                border-radius: 16px;
                background: $background-color;
                border: 2px solid #A0B3BD;
            }

            ul {
                padding-inline-start: 0;

                li {
                    list-style-type:none;
                    cursor: pointer;
                    padding: 5px 10px;

                    &:before {
                        content: '';
                        display: inline-block;
                        height: 1em;
                        width: 1em;
                        background-image: url('../../Images/tree.svg');
                        background-size: contain;
                        background-repeat: no-repeat;
                        margin-right: 1em;
                    }

                    .offsetAllocation {
                        float: right;
                        font-weight: $medium-font-weight;
                    }
                }
            }
        }
    }

    .offsetImageContainer {
        width: 760px;

        .slider-wrapper {
            border-radius: 16px;

            .sconeLegend {
                background: #34747380;
                position: absolute;
                bottom: 40px;
                color: #fff;
                padding: 20px;
                font-size: 12px;
                text-align: left;
                width: 100%;

                .legendTitle {
                    font-weight: $medium-font-weight;
                    font-size: $normal-title;
                    margin: 0;
                }

                .legendDescription {
                    font-size: 16px;
                }

                .legendMoreOrLess {
                    color: #F1C765;
                    font-weight: $medium-font-weight;
                    cursor: pointer;
                }
            }
        }

        img {
            width: 760px;
            height: 420px;
        }
    }

    .mobileOffsetTitle {
        font-weight: $medium-font-weight;
        font-size: $small-title;
        color: $scone-cta;
        padding: 20px 0;
    }

    .mobileOffsetDescription {
        color: $scone-cta;
    }
}

.carousel .control-dots .dot {
    opacity: 1 !important;
}

.carousel .control-dots .dot.selected {
    width: 16px;
    border-radius: 16px;
    background: #F1C765 !important;
}

.cover {
    object-fit: cover;
}

@media (max-width: 768px) {
    .offsetContainer {
        flex-direction: column;

        .offsetDescription {
            width: 100%;
            padding: 0;

            .offsetTitle { font-size: $small-title }
        }

        .offsetImageContainer {
            width: auto;
            margin: 20px -20px;
            .slider-wrapper { border-radius: 4px; }
        }
    }
}

@media (min-width: 768px) {
    .carousel .control-dots {
        width: auto !important;
        right: 0;
        margin: 10px !important;
    }
}