@import '../../library';

.success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 100px 40px 200px;

  .title {
    font-size: $large-title;
    color: $scone-green;
    font-weight: bold;
  }

  .subtitle {
    font-size: $small-title;
    color: $scone-black;
    padding-bottom: 50px;
    border-bottom: 1px solid $scone-green;
  }

  .options {
    display: flex;
    justify-content: space-around;
    align-items: center;

    a {
      color: inherit;
      text-decoration: inherit;
    }

    img {
      width: 30%;
    }

    > div {
      height: 120px;
      cursor: pointer;
      padding: 80px;
      border-radius: 16px;
      border: 4px solid $scone-green;

      img {
        width: 200px;
        height: 100px;
      }
    }
  }
}

@media (max-width: 768px) {
  .success {
    .title {
      font-size: $normal-title;
    }
    .options {
      margin-top: 40px;
      flex-direction: column;
      div {
        width: 100%;
        padding: 0 0 20px;
        margin-bottom: 40px;
      }
    }
  }
}