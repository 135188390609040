@import "library";
body {
  font-family: $primary-font;
  background: $background-color;
  margin: 0
}

.page {
  max-width: 1440px;
  margin: auto
}

button {
  border-radius: 8px;
  background: $scone-cta;
  color: $scone-white;
  font-weight: bold;
  font-size: $large-text;
  padding: 20px 80px;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.3;
  }
}

.bold {
  font-weight: bold;
}
