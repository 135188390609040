@import "../../../library";

.contributionContainer {
    display: flex;
    padding-inline-start: 0;

    li {
        list-style-type: none;
        padding-right: 40px;
        width: 100%;
        &:last-child { padding-right: 0}
    }
}

.paymentLogos {
    display: none;
    justify-content: center;
    margin-top: 40px;
    height: 30px;
}
