@import "../../library";

.paymentPageContainer {
    display: flex;
    padding: 40px;

    .paymentInformation {
        width: 50%;

        .paymentTitle {
            font-weight: $medium-font-weight;
            font-size: 50px;
            color: $scone-green;
            padding-bottom: 20px;
        }

        .contributionOptionContainer {
            &:hover {
                transform: none;
                filter: none;
            }
        }

        .contributionInfo {
            padding: 20px 0;
            margin: 0 auto;
        }

        .securityInfo {
            padding: 20px 0;
            display: flex;
            justify-content: center;
            color: #4B6266;
            font-size: 12px;

            .securityText {
                display: flex;

                div {
                    padding-left: 10px;
                }
            }

            .logos {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                width: 50%;
                padding: 0 10px;

                .stripeLogo {
                    max-width: 100px;
                    height: 50px;
                    margin-top: -12px;
                    margin-right: 5px;
                }

                .kboLogo {
                    max-width: 60px;
                    height: 25px;
                }
            }
        }
    }

    .formContainer {
        padding-top: 93px;
        text-align: center;
        margin: 0 0 0 auto;
        width: 45%;
    }
}

@media (max-width: 768px) {
    .paymentPageContainer {
        display: inherit;
        padding: 20px;

        .paymentInformation {
            width: 100%;
            padding: 0 0 20px 0;

            .securityInfo {
                flex-direction: column;

                .logos {
                    padding-top: 20px;
                    width: 100%;
                }
            }
        }

        .formContainer {
            width: 100%;
            padding: 0;
        }
    }
}