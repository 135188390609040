@import "../../library";

.subscriptionPage {
    text-align: center;
    padding: 40px;

    .title {
        font-weight: $medium-font-weight;
        font-size: $large-title;
        color: $scone-green;
    }

    .pageGroup {
        padding: 50px 0;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .subscriptionPage {
        text-align: left;
        padding: 20px;
        .title { font-size: $normal-title }

        .pageGroup { padding: 30px 0 }
    }
}