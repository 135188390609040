@import '../../library';

.offsetContainerHomePage {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .offsets {
    width: 40%;
    padding: 0 80px;

    .offsetTitle {
      font-size: $normal-title;
      font-weight: bold;
      color: $scone-green;
      margin-bottom: 20px;
    }

    .offsetSubtitle {
      font-size: $small-text;
      color: $scone-black;
      margin-bottom: 20px;
    }

    .offsetList {
      font-family: $secondary-font;
      color: $scone-green;
      font-size: $large-text;

      .selectedOffset {
        font-weight: bold;
        border-radius: 16px;
        background: $background-color;
        border: 2px solid #A0B3BD;
      }

      ul {
        padding-inline-start: 0;

        li {
          list-style-type: none;
          cursor: pointer;
          padding: 5px 10px;

          &:before {
            content: '';
            display: inline-block;
            height: 1em;
            width: 1em;
            background-image: url('../../Images/tree.svg');
            background-size: contain;
            background-repeat: no-repeat;
            margin-right: 1em;
          }

          .offsetAllocation {
            float: right;
            font-weight: $medium-font-weight;
          }
        }
      }
    }

    button {
      color: $scone-white;
      background-color: $scone-blue;
    }
  }

  .imageContainer {
    width: 40%;

    .image {
      width: 100%;
      height: 100%;
      border-radius: 16px;
      background-size: cover;
    }
  }
}

@media (max-width: 768px) {
  .offsetContainerHomePage {
    flex-direction: column-reverse;
    .offsets {
      width: 100%;
      padding: 0;

      .offsetTitle {
        padding-top: 20px;
      }

      button {
        width: 100%;
        background-color: transparent;
        color: $scone-blue;
        border: 2px solid $scone-blue;
      }
    }

    .imageContainer {
      width: 100%;
      .image {
        height: 60vw;
        border-radius: 16px;
        background-size: cover;
      }
    }

    .mobileSwitch {
      display: flex;
      flex-direction: column-reverse;
    }


  }
}