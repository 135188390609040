@import '../../library';

.homeHeader {
  display: flex;
  margin-bottom: 250px;

  .left {
    margin-top: 80px;
    width: 50%;

    .title {
      font-weight: $medium-font-weight;
      font-size: 58px;
      color: $scone-green;
      padding: 20px 0;
    }

    .subtitle {
      padding: 5px 0;
      font-size: 22px;
      color: $scone-black;
    }

    .signUpButton {
      margin-top: 80px;
      background-color: $scone-cta;
      color: $scone-white;
      width: 60%;
      border-radius: 8px;
    }

    .appButtons {
      margin-top: 10px;
      display: flex;
      width: 62%;

      .link {
        margin-right: 10px;
        width: 100%;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $scone-green;
        color: $scone-green;
        font-size: $small-text;
        background-color: transparent;
        width: 100%;
        padding: 5px 20px;
        border-radius: 8px;
      }

      span {
        margin-left: 10px;
      }
    }
  }

  .right {
    height: 600px;
    width: 600px;

    .absolute {
      position: absolute;
    }

    .yellowCircle {
      border-radius: 50%;
      left: 50%;
      background-color: #F7C359;
      height: 600px;
      width: 600px;
    }

    .greenCircle {
      left: 50%;
      background-image: url("../../Images/greenCircle.svg");
      background-size: cover;
      height: 600px;
      width: 600px;
      border-radius: 50%;
      border: 5px solid $scone-yellow;
    }

    .mobileDemo {
      background-image: url("../../Images/mobileDemoGreen.png");
      background-size: cover;
      height: 650px;
      width: 450px;
      margin-top: 50px;
      left: 53%;
    }
  }
}

@media (max-width: 768px) {
  .homeHeader {
    flex-direction: column-reverse;

    .right {
      width: 90vw;
      height: 90vw;
      margin-bottom: 100px;

      .absolute {
        position: absolute;
      }

      .yellowCircle {
        visibility: hidden;
      }

      .greenCircle {
        left: 5%;
        width: 90vw;
        height: 90vw;
      }

      .mobileDemo {
        top: 15%;
        left: 15%;
        width: 70vw;
        height: 100vw;
      }
    }

    .left {
      width: 100%;
      .title {
        text-align: center;
        font-size: $normal-title;
      }

      .subtitle {
        text-align: center;
        font-size: 16px;
      }

      .signUpButton {
        width: 100%;
      }

      .appButtons {
        width: 100%;
      }
    }
  }
}