@import '../../library';

.MuiAccordionDetails-root {
  display: block;
}

.MuiAccordionSummary-content {
  overflow: hidden;
}

.changeAccount {
  text-decoration: underline;
  cursor: pointer;
}

.checkoutPaymentHeader {
  text-align: left;

  .checkoutPaymentLogos {
    margin-top: 10px;
    margin-left: -5px;
    display: flex;
    justify-content: left;
    height: 20px;
  }

  span {
    margin-right: 20px;
  }
}

.paymentOptions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #A0B3BD;
  padding-bottom: 20px;
  margin-bottom: 20px;

  .paymentOption {
    cursor: pointer;
    display: block;
    margin: 10px 0 20px 0;
    width: 48%;
    font-size: 1em;
    font-family: $primary-font;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    outline: 0;
    border-radius: 8px;
    background: $background-color;
    border: 1px solid #A0B3BD;
    box-sizing: border-box;
    padding: 20px;

    &.selected {
      border: 2px solid $scone-cta;
    }
  }
}

.StripeElement--complete {
  border-color: green;
}

.StripeElement--invalid {
  border-color: darkred;
}

.cardInfo {
  display: flex;
  justify-content: space-between;
}

.checkoutForm {
  width: 100%;

  .shouldLogin {
    color: darkred;
    padding: 10px 0;
  }

  .cardExpiry, .cardCvc {
    width: 48%
  }

  .input-container {
    position: relative;
    flex-direction: column;
    display: none;

    &.show {
      display: flex;
    }

    select, input, .StripeElement {
      display: block;
      margin: 10px 0 20px 0;
      width: 100%;
      font-size: 1em;
      font-family: $primary-font;
      box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
      rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
      outline: 0;
      border-radius: 8px;
      background: $background-color;
      border: 1px solid #A0B3BD;
      box-sizing: border-box;
      padding: 20px;
    }

    label {
      border-radius: 500px;
      color: gray;
      padding: 0 5px;
      position:absolute;
      top:-8px;
      left:10px;
      background-color: $background-color;
      transform: translate(0, 8px) scale(0.8);
    }

    .error {
      border-color: darkred;
    }

    .valid {
      border-color: green;
    }
  }
  button {
    width: 100%;
    margin: 0;
  }
}

.double-input-container {
  display: flex;
  justify-content: space-between;

  > div {
    width: 48%;
  }
}

.paymentOptionWarning {
  display: flex;
  padding: 20px 10px;
  color: #4B6266;
  text-align: left;

  div {
    margin-left: 10px;
  }

  span {
    cursor: pointer;
    font-weight: bold;
  }
}

.errorDialog {
  padding: 40px;

  .title {
    color: $scone-green;
    font-size: $normal-title;
    padding-bottom: 20px;
  }

  .errorInfo {
    color: $scone-black;
    font-size: $large-text;
  }

  .return {
    font-size: $small-title;
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .double-input-container {
    flex-direction: column;

    > div {
      width: 100%;
    }
  }
}