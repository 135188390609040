.paymentIcons {
  display: flex;
  justify-content: center;

  img {
    margin: 0 10px;
    max-width: 64px;
    max-height: 64px;
  }

  .visa, .sepa {
    padding: 3px;
  }
}