body > #root > div {
  height: 100vh;
}

.Calculator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Montserrat, sans-serif;
  background: linear-gradient(180deg, #04A996 0%, #23CB93 100%);
}

.Calculator-title {
  font-weight: bold;
  font-size: 44px;
  margin-bottom: 57px;
}

.Calculator-block {
  min-width: 70vw;
  min-height: 70vh;
  border: 5px solid #D9D9D9;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  overflow: hidden;
}

.Calculator-footer {
  color: white;
  font-size: 14px;
  margin-top: 59px;
  margin-bottom: 53px;
}

.progress-container {
  height: 20px;
  margin-top: 120px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.progress-text {
  font-size: 16px;
  margin-left: 8px;
  color: #A6A6A6;
}

.Calculator-block-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 100px;
}

.Calculator-select-button {
  width: 100px;
  height: 100px;
  border: 2px solid #D9D9D9;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
  cursor: pointer;
}

.Calculator-select-button-selected {
  border: 2px solid #04A995;
}

.Calculator-block-footer {
  width: 100%;
  height: 95px;
  background-color: #F0F1F3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Calculator-block-content {
  margin-bottom: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.next-button {
  background-color: #6E6E6E;
  color: white;
  border-radius: 5px;
  font-size: 20px;
  width: 158px;
  height: 46px;
  padding: inherit;
}

.next-back-button {
  color: #A6A6A6;
  font-size: 16px;
  cursor: pointer;
}
.next-button-enabled {
  background-color: #3A3A3A;
}

.slider-container {
  text-align: center;
  width: 20vw;
}

.Calculator-block-score {
  font-size: 70px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
}

.Calculator-block-score-footer {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
}

.icon-image {
  /*height: 50px;*/
  /*width: 50px;*/
}

.strategy-header {
  margin-top: 30px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
}

.strategy-container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 50vw;
}

.strategy-box {
  display: flex;
  flex-direction: column;
  margin: 0 5px;
}

.strategy-image {
  width: 15vw;
  height: 12vw;
}

.strategy-text {
  margin: 0;
  padding: 15px 15px 8px;
  background-color: #F0F1F3;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  min-height: 50px;
}

.strategy-subtext {
  margin: 0;
  padding: 0 15px 15px;
  background-color: #F0F1F3;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}
