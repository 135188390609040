$primary-font: 'Montserrat', sans-serif;
$secondary-font: 'Poppins', 'Montserrat', sans-serif;

$large-title: 64px;
$normal-title: 32px;
$small-title: 24px;

$large-text: 18px;
$small-text: 14px;

$medium-font-weight: 500;

$scone-green: #075553;
$scone-cta: #179E7F;
$scone-yellow: #F1C765;
$scone-blue: #179E7F;
$scone-black: #404040;
$scone-white: #FAFAFA;
$background-color: #E5E5E5;
