@import "../../library";

.contributionOptionContainer {
    text-align: center;
    border-radius: 16px;

    &:hover {
        transform: scale(1.1);
        filter: drop-shadow(0px 6px 24px rgba(0, 0, 0, 0.35));
    }

    .optionTitle {
        font-weight: bold;
        font-size: $normal-title;
        color: $scone-green;
        padding: 10px;
    }

    .optionSubTitle {
        font-size: $small-title;
        color: $scone-green;
    }

    .optionPrice {
        font-weight: $medium-font-weight;
        font-size: 42px;
    }

    .optionDescription {
        font-size: $small-text;
        color: #4B6266;
    }

    .optionExtra {
        font-weight: bold;
        font-size: $small-text;
        color: #4B6266;
        padding: 20px;
    }

    .pm, .pmNoMargin {
        font-size: $small-text;
        line-height: 20px;
    }

    .pm {
        margin-left: -15px;
        margin-top: -25px;
    }

    .optionPriceContainer, .optionSlider {
        display: flex;
        justify-content: center;
        height: 100px;
    }

    .optionPriceContainer {
        align-items: center;
        color: $scone-green;
    }

    .optionSlider {
        padding: 0 40px;
        align-items: flex-end;
    }
}

.MuiSlider-valueLabel > span {
    width: max-content !important;
    height: fit-content !important;
    transform: rotate(0deg) translateX(-30%) translateY(5px);
    position: relative;
    background: #46a785;
    border-radius: 16px;
    color: #FCFCFC;
    left: -4px;
    top: -10px;
    font-family: $secondary-font;
    font-weight: 600;
    font-size: 13px;
}

.MuiSlider-valueLabel > span:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-top-color: #46a785;
    border-bottom: 0;
    margin-left: -5px;
    margin-bottom: -5px;
}


.MuiSlider-valueLabel > span > span {
    margin: 9px;
    transform: rotate(0deg) !important;
}

@media (min-width: 768px) and (max-width: 1200px) {
    .optionTitle, .optionSubTitle {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .optionTitle {
        height: 60px;
    }

    .optionSubTitle {
        height: 50px;
    }
}

@media (max-width: 768px) {
    .contributionOptionContainer {
        text-align: center;

        .optionDescription { display: none; }
        .optionExtra { display: none; }
        .optionSlider { padding: 0 40px}
        .pm { margin-left: 0 }
    }
}