@import '../../library';

.userExplanation {
  position: relative;
  display: flex;
  margin: 80px 0;
  background-color: #F5F6F7;
  border-radius: 16px;
  height: 400px;

  .yellowSquare {
    position: absolute;
    height: 200px;
    width: 200px;
    right: -7px;
    bottom: -7px;
    z-index: -1;
    border-radius: 16px;
    background-color: $scone-yellow;
  }

  .imageContainer {
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;

    .image {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 100%;
      height: 100%;
      border-radius: 16px;
    }
  }

  .userInfo {
    padding: 80px 80px 80px 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40%;

    .title {
      display: flex;
      padding-top: 20px;
      font-size: $normal-title;
      font-weight: bold;
      color: $scone-green;
    }

    .subtitle {
      display: flex;
      padding: 20px 0;
      color: #404040
    }

    .buttons {
      display: flex;
      justify-content: space-between;

      .control-dots {
        display: flex;

        .dot {
          width: 8px;
          height: 8px;
          cursor: pointer;
          margin: 0 8px;
          border-radius: 50%;

          background: $scone-green;

          &.selected {
            background-color: #F7C359;
            width: 16px;
            border-radius: 16px;
          }
        }
      }

      .next {
        color: #179E7F;
        cursor: pointer;
      }
    }
  }
}


@media (max-width: 768px) {
  .userExplanation {
    flex-direction: column-reverse;
    padding: 20px;
    height: 600px;

    .yellowSquare {
      display: none;
    }

    .imageContainer {
      width: 100%;

      .image {
        background-size: cover;
      }
    }

    .userInfo {
      padding: 0;
      width: 100%;
    }
  }
}