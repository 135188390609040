@import "../../library";

.header {
  padding: 40px 40px 0 40px;
  display: flex;
  justify-content: space-between;
  color: #3D4B45;

  .scone {
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: #3D4B45;
  }

  .headerTitle {
    font-size: 36px;
    display: flex;
    align-items: center;

    .partner {
      margin-left: 50px;
      font-size: 10px;
      text-align: center;

      img {
        width: 100px;
        height: 50px;
        margin-top: -10px
      }
    }
  }

  .headerMenu {
    font-size: $large-text;
    display: flex;
    align-items: center;

    a, div {
      padding: 10px 20px;
    }

    .signupButton {
      cursor: pointer;
      background-color: $scone-green;
      border-radius: 8px;
      color: $scone-white;
    }
  }
}

@media (max-width: 768px) {
  .header {
    padding: 20px 20px 10px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    .headerMenu {
      align-items: flex-start;

      a, div {
        display: none
      }
      .signupButton {
        display: inherit;
      }
    }
  }
}