@import '../../library';

.backToTop {
  display: flex;
  justify-content: flex-end;
  background: $scone-green;
  padding-top: 40px;

  div {
    margin: 0 20px 20px 0;
    padding: 10px;
    color: $scone-white;
    border: 1px solid $scone-white;
    border-radius: 8px;
    cursor: pointer;
  }
}

.footerContainer {
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  background: $scone-green;
  z-index: -1;
}

.footer {
  color: $scone-white;
  display: flex;
  justify-content: space-between;
  padding: 40px;

  .left {
    width: 40%;
    .title {
      font-size: 36px;
    }

    .contact {
      margin-top: 40px;

      div {
        padding-top: 5px;
      }
    }
  }

  .right {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    a {
      color: inherit;
      text-decoration: inherit;
    }

    .links {
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      font-weight: 500;

      .pageLinks {
        width: 50%;
        display: flex;
        justify-content: space-between;

        .column {
          div {
            cursor: pointer;
            margin-bottom: 10px;
          }
        }
      }

      .icons {
        margin-top: 10px;
        display: flex;
        justify-content: center;

        > * {
          display: none;
          &.show {
            display: initial;
          }
        }
      }
    }
  }
}
.privacy {
  display: none; //display: flex
  color: $scone-white;
  justify-content: center;
  align-items: center;
  div {
    margin: 0 40px;
    cursor: pointer;
  }
}

.copyright {
  padding: 20px 40px;
  margin: auto;
  display: flex;
  justify-content: center;
  color: gray;
  font-size: $small-text;
}

@media (max-width: 768px) {
  .footer {
    padding: 20px;
    flex-direction: column;

    .left, .right{
      width: 100%;
    }

    .right {
      .links {
        flex-direction: column;
        .pageLinks {
          flex-direction: column;
          padding: 20px 0;
          .column {
            display: flex;
            justify-content: space-between;
          }
        }
        .icons {
          justify-content: flex-start;
        }
      }
      .privacy {
        padding-top: 20px;
      }
    }
  }

  .copyright {
    padding: 20px;
    color: gray;
    font-size: $small-text;
  }
}