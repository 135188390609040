@import '../../library';

.homeContainer {
  border-left: 10px solid #F7C359;
}

.section {
  &.darker {
    background-image: linear-gradient(#B3E1E600, #9FDFE655);
  }

  .homePage {
    padding: 80px 40px;

    .sconeExplanation {
      display: flex;
      justify-content: space-between;

      .info {
        width: 40%;
      }

      .readMore {
        text-decoration: underline $scone-green;
        color: $scone-green;
      }

      .title {
        color: $scone-green;
        font-weight: bold;
        font-size: $normal-title;
        width: 60%;
      }

      .subtitle {
        padding-top: 40px;
        color: $scone-black;
      }

      .awardsTitle {
        color: $scone-green;
        font-weight: bold;
        font-size: $small-title;
        margin: 120px 0 40px;
      }

      .awards {
        background-image: url("../../Images/awards.svg");
        background-size: contain;
        width: 100%;
        height: 200px;
        background-repeat: no-repeat;
        mix-blend-mode: multiply;
      }


      .sconeOptions {
        width: 40%;
        display: flex;
        flex-direction: column;
        padding-inline-start: 0;
        margin-block-start: 0;

        li {
          list-style-type: none;
          margin-bottom: 20px;
          width: 100%;
          text-align: center;

          &:last-child {
            padding-right: 0
          }

          button {
            background-color: $scone-white;
            padding: 80px 50px;
            font-weight: bold;
            font-size: 22px;
            width: 100%;
            color: $scone-green;
            border-bottom: 4px solid $scone-yellow;
            text-align: center;

            .buttonTitle {
              text-align: center;
              font-weight: bold;
              color: $scone-green;
            }

            .buttonSubtitle {
              text-align: center;
              font-weight: 400;
              color: $scone-green;
            }
          }
        }
      }
    }

    .team {
      display: flex;
      padding: 80px 0;
      justify-content: space-between;

      img {
        width: 50%;
        border-radius: 16px;
      }

      .teamInfo {
        width: 50%;
        padding: 0 80px;
        margin: auto;

        .title {
          padding-top: 20px;
          font-size: $normal-title;
          font-weight: bold;
          color: $scone-green;
        }

        .subtitle {
          padding: 20px 0;
          color: $scone-black
        }

        button {
          background-color: $scone-blue;
        }

        .socialMediaContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;
          color: $scone-green;

          .link {
            width: 40%;
            display: flex;
            align-items: center;

            span {
              margin-left: 10px;
            }
          }
        }
      }
    }

    .offsetContainer {
      padding: 40px 0;
    }

    .mobileApp {
      scroll-margin: 200px;
      display: flex;
      justify-content: space-between;
      color: $scone-green;
      margin: 200px 0 0;

      .mobileContainer {
        width: 50%;

        .mobileDemo {
          position: absolute;
          background-image: url("../../Images/mobileDemo.png");
          background-size: cover;
          height: 650px;
          width: 450px;
          margin-top: -200px;
        }
      }


      .info {
        width: 50%;

        .title {
          margin-bottom: 20px;
          font-size: $normal-title;
          font-weight: bold;
        }

        .subtitle {
          margin-top: 40px;
          font-size: $small-text;
        }

        .appButtons {
          margin-top: 10px;
          display: flex;
          width: 62%;

          .link {
            margin-right: 10px;
            width: 100%;
          }

          button {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $scone-green;
            color: $scone-green;
            font-size: $small-text;
            background-color: transparent;
            width: 100%;
            padding: 5px 20px;
            border-radius: 8px;
          }

          span {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .homeContainer {
    border: none;
  }

  .section {
    .homePage {
      padding: 20px;

      .sconeExplanation {
        text-align: center;
        flex-direction: column;

        .title {
          width: 100%;
        }

        .sconeOptions {
          width: 100%;
        }
      }

      .team {
        flex-direction: column;

        img, .teamInfo {
          width: 100%;
          padding: 0;

          button {
            width: 100%;
          }
        }
      }

      .mobileApp {
        flex-direction: column;

        .mobileContainer {
          width: 90vw;
          height: 90vw;

          .mobileDemo {
            left: 15%;
            width: 70vw;
            height: 100vw;
          }
        }

        .info {
          width: 100%;
          margin-top: -100px;

          .appButtons {
            width: 100%;
          }
        }
      }
    }
  }
}