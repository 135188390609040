@import '../../library';

.signUpContainer {
  border-left: 10px solid #F7C359;
  display: table;
  width: 100%;
}

.signUpPage {
  .content {
    padding: 80px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      font-weight: $medium-font-weight;
      font-size: $large-title;
      color: $scone-green;
    }

    .subTitle {
      color: $scone-green;
      margin: 40px 0;
      width: 60%;
    }

    .signUpForm {
      width: 70%;
      text-align: left;

      .optional {
        margin: 40px 0 20px;
        padding-bottom: 10px;
        color: #347473;
        width: 100%;
        border-bottom: 1px solid #347473;
      }

      .input-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        label {
          color: $scone-green;
          font-weight: bold;
          width: 30%;
          //margin-right: 40px;
        }

        input, select, textarea, .captcha, .checkboxes, .submitButton, .buttons {
          width: 65%;
        }

        input, select, textarea {
          display: block;
          margin: 20px 0;
          font-size: 1em;
          font-family: $primary-font;
          box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
          rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
          outline: 0;
          border-radius: 8px;
          background: $background-color;
          border: 1px solid #347473;
          box-sizing: border-box;
          padding: 20px;
        }

        .error {
          border-color: darkred;
        }

        .valid {
          border-color: green;
        }

        .submitButton {
          background-color: $scone-blue;
          border-radius: 8px;
          margin: 40px 0 80px;
        }

        .captcha {
          margin-top: 40px;
          display: flex;
          justify-content: center;
        }

        .checkboxes, .buttons {
          display: flex;
          justify-content: space-between;
          color: #347473
        }

        .buttons {
          margin: 20px 0;

          button {
            background-color: transparent;
            border: 1px solid #347473;
            color: #347473;
            width: 30%;
            padding: 20px;

            &.checked {
              background-color: #347473;
              color: $scone-white;
            }
          }
        }
      }
    }
  }
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #347473;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #347473;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #347473;
}

@media (max-width: 768px) {
  .signUpContainer {
    border: none;
  }


  .signUpPage {

    .content {
      padding: 40px 20px;
      text-align: left;
      align-items: flex-start;

      .title {
        font-size: $normal-title;
      }

      .subTitle {
        width: 100%;
      }
      .signUpForm {
        width: 100%;

        .checkboxes {
          margin-bottom: 20px;
        }

        .input-container {
          align-items: flex-start;
          flex-direction: column;

          .submitButton {
            margin: 20px 0;
            background-color: $scone-cta;
          }

          label {
            width: 100%;
            padding-right: 0;
          }

          input, select, textarea, .captcha, .checkboxes, .submitButton, .buttons {
            width: 100%;
          }
        }
      }
    }
  }
}