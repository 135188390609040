@import "../../../library";

.qaContainer {
    display: flex;
    text-align: left;

    .qaLeft {
        width: 40%;
        padding-right: 50px;

        .lightBulb {
            color: $scone-green;
            font-size: 300px;
        }

        .qaTitle {
            font-weight: bold;
            font-size: 34px;
            color: #1A1C29;
            padding: 20px 0;
        }
    }

    .qaRight {
        width: 60%;

        .qaQuestion {
            font-weight: $medium-font-weight;
            font-size: $small-title;
            color: #1A1C29;
            padding: 20px 0;
        }

        .qaAnswer {
            font-size: $large-text;
            color: #212121;
        }
    }
}

.accordion {
    background-color: transparent !important;
}

@media (max-width: 768px) {
    .qaContainer {
        flex-direction: column;

        .qaLeft {
            width: 100%;
            padding: 0;

            .qaTitle {
                color: $scone-cta;
                font-size: $small-title;
                padding-bottom: 10px;
            }
        }

        .qaRight {
            width: 100%;

            .qaQuestion {
                font-weight: $medium-font-weight;
                font-size: $large-text;
                color: $scone-cta;
            }

            .qaAnswer {
                font-size: $small-text;
            }
        }
    }
}